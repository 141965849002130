<template>
  <v-app>
    <v-app-bar app class="justify-center">
      <div style='margin:auto;'>Gridcoin Clicker</div>
    </v-app-bar>
    <v-main>
      <v-container>
        <v-row>
          <v-col class="col-6 justify-center">
            <v-card>
            <v-card-text>
            <MainIcon @addGridcoin="addGridcoin()"/>
            <MainCounter :miningStats=gameState.miningStats />
            </v-card-text>
            </v-card>
          </v-col>
          <v-col class="col-6">
            <ButtonControls
              :minersData=gameState.miners
              :miningStats=gameState.miningStats
              @buy="buy($event)"
              @sell="sell($event)"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-footer class="justify-center">
      2020 Vladimir Tsarev (sau412)
      <img src='https://arikado.xyz/counter/invisible.php?site=gridcoin_clicker'>
    </v-footer>
  </v-app>
</template>

<script>
import MainIcon from './components/MainIcon';
import MainCounter from './components/MainCounter';
import ButtonControls from './components/ButtonControls';

export default {
  name: 'App',

  components: {
    MainIcon,
    MainCounter,
    ButtonControls,
  },
  title: 'Gridcoin Clicker',
  data: () => ({
    gameState: {
      miningStats: {
        magnitudeUnit: 0.2,
        magnitude: 0,
        workunitsPerTick: 0,
        gridcoinPerTick: 0,
        gridcoinBalance: 0,
        lastTickTimestamp: 0,
      },
      miners: [
        {id: 0, name: "Browser Miner", basePrice: 10, count: 0, power: 1, },
        {id: 1, name: "Raspberry Pi", basePrice: 100, count: 0, power: 4, },
        {id: 2, name: "Office PC", basePrice: 1000, count: 0, power: 10, },
        {id: 3, name: "Gaming PC", basePrice: 5000, count: 0, power: 30, },
        {id: 4, name: "Mining Station", basePrice: 10000, count: 0, power: 100, },
        {id: 5, name: "Box of GPUs", basePrice: 50000, count: 0, power: 234, },
        {id: 6, name: "Data Center Rack", basePrice: 100000, count: 0, power: 500, },
      ],
    },
  }),
  methods: {
    addGridcoin() {
      this.gameState.miningStats.gridcoinBalance ++;
    },
    buy: function(id) {
      let miner = this.gameState.miners[id];
      let price = Math.floor(miner.basePrice * Math.pow(1.1, miner.count));
      if(price <= this.gameState.miningStats.gridcoinBalance) {
        miner.count ++;
        this.gameState.miningStats.gridcoinBalance -= price;
      }
    },
    sell: function(id) {
      let miner = this.gameState.miners[id];
      let price = Math.floor(miner.basePrice * Math.pow(1.1, miner.count - 1));
      if(miner.count > 0) {
        miner.count --;
        this.gameState.miningStats.gridcoinBalance += price;
      }
    },
    tick: function() {
      let stats = this.gameState.miningStats;
      let miners = this.gameState.miners;
      let currentTimestamp = + new Date();
      let intervalTicks = (currentTimestamp - this.lastTickTimestamp) / 100;
      if(isNaN(intervalTicks) || intervalTicks < 1 || intervalTicks > 600) {
        console.log(intervalTicks);
        intervalTicks = 1;
      }
      stats.workunitsPerTick = 0;
      miners.forEach(function(minerInfo) {
        stats.workunitsPerTick += minerInfo.power * minerInfo.count / 10;
      });
      stats.magnitude = (stats.magnitude * 0.9999 + stats.workunitsPerTick * 0.0001);
      stats.gridcoinPerTick = stats.magnitudeUnit * stats.magnitude / 10;
      stats.gridcoinBalance += stats.gridcoinPerTick * intervalTicks;
      this.lastTickTimestamp = currentTimestamp;
    },
    loadFromStorage: function() {
      let raw_save = window.localStorage.getItem("clicker");
      try {
        let save = JSON.parse(raw_save);
        if(typeof save === 'object' && save !== null) {
          this.gameState = save;
        }
      }
      catch(e) {
        console.log("Cannot load from save");
      }
    },
    saveToStorage: function() {
      window.localStorage.setItem("clicker",JSON.stringify(this.gameState));
    },
  },
  created: function() {
    window.document.title = 'Gridcoin Clicker';
    this.loadFromStorage();
    setInterval(this.tick, 100);
    setInterval(this.saveToStorage, 10000);
  },
};
</script>
