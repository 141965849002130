<template>
  <div>
    <v-list-item @click=buy :disabled=isBuyDisabled>
      <v-list-item-content>
        <v-list-item-title>{{minerInfo.name}}</v-list-item-title>
        <v-list-item-subtitle>Price: {{currentPrice}} GRC, power: {{minerInfo.power}}</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action><h2>{{minerInfo.count}}</h2></v-list-item-action>
    </v-list-item>
  </div>
</template>

<script>
export default {
  name: 'MinerControls',
  props: {
    miningStats: Object,
    minerInfo: Object,
  },
  methods: {
    buy: function() {
      this.$emit("buy", this.minerInfo.id);
    },
    sell: function() {
      this.$emit("sell", this.minerInfo.id);
    },
  },
  computed: {
    currentPrice: function() {
      return Math.floor(this.minerInfo.basePrice * Math.pow(1.1, this.minerInfo.count));
    },
    isBuyDisabled: function() {
      if(this.miningStats.gridcoinBalance >= this.currentPrice) return false;
      return true;
    },
    isSellDisabled: function() {
      if(this.minerInfo.count > 0) return false;
      return true;
    },
  }
}
</script>
