<template>
  <div style='text-align:center;'>
    <p>Magnitude: {{miningStats.magnitude.toFixed(3)}}</p>
    <p>Magnitude Unit: {{miningStats.magnitudeUnit}}</p>
    <p>GRC/sec: {{(miningStats.gridcoinPerTick * 10).toFixed(3)}}</p>
    <p>Balance:</p>
    <h1>{{miningStats.gridcoinBalance.toFixed(3)}}&nbsp;GRC</h1>
  </div>
</template>

<script>
export default {
  name: 'MainCounter',
  props: {
    miningStats: Object
  }
}
</script>
