<template>
  <div style='text-align:center'>
    <img alt="Click here to earn Gridcoin" id=logo src="../assets/GRCLogoOnly_Purple_Transparent.png" @click=addGridcoin>
  </div>
</template>

<script>
export default {
  name: 'MainIcon',
  methods: {
    addGridcoin: function() {
      this.$emit('addGridcoin')
    },
  }
}
</script>

<style>
#logo {
  width: 50%;
  margin: auto;
}
#logo:hover {
  filter: sepia(50%);
  transform: scale(1.05);
}
#logo:active {
  transform: scale(1.02);
}
</style>
