<template>
  <div>
    <v-card>
    <minerControls
      v-for="minerInfo in minersData"
      :key=minerInfo.name
      :minerInfo=minerInfo
      :miningStats=miningStats
      @buy="buy($event)"
      @sell="sell($event)"
    />
    </v-card>
  </div>
</template>

<script>
import MinerControls from './MinerControls';

export default {
  name: 'ButtonControls',
  props: {
    miningStats: Object,
    minersData: Array,
  },
  components: {
    MinerControls,
  },
  methods: {
    buy: function(id) {
      this.$emit("buy", id);
    },
    sell: function(id) {
      this.$emit("sell", id);
    },
  }
}
</script>

